<template>
  <div/>
</template>

<script>
import BaseAction from '@/components/actions/BaseAction';
import RouteService from '@/services/RouteService';
import ReportService from '@/components/services/ReportService';

export default {
  mixins: [
    BaseAction,
  ],
  name: 'OpenReport_10_2',
  mounted() {
    if (!this.selected) {
      return;
    }
    this.open();
  },
  methods: {
    async open() {
      var certificateID = this.selected.vccertificateregisterId;
      var store = this.$store;
      var scheduleVersionID = await RouteService.getCurrentScheduleVersion(certificateID, store.getters['period/current'].getTime());
      if (scheduleVersionID) {
        var reportID = "88d5c390-9ca8-442b-9edc-20562d12c213";
        var _vals = `{"certificateID":"${certificateID}","scheduleVersionID":"${scheduleVersionID}"}`;
        this.$nextTick(()=>{
          ReportService.buildReport(reportID, _vals, 'application/vnd.ms-word.document.macroEnabled.12', '10.2 Свидетельство об осуществлении перевозок', store); 
        });
      } else {
        jet.msg({
          text: 'Не найдена версия расписания, действующая на рабочую дату',
          color: 'warning'
        });
      }
    },
  },
}
</script>